.nav-scroller {
  position: fixed;
  top: 50%;
  right: 15%;
  opacity: 1;
  z-index: 2;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-in;
}

.nav-pointer {
  position: absolute;
  top: -0.35rem;
  left: -0.35rem;
  height: 1rem;
  width: 1rem;
  margin: 4rem 0;
  border-bottom: 1px solid #5c9ad2;
  border-right: 1px solid #5c9ad2;
  transform: translateY(0) rotate(-135deg);
  transition: all 0.5s ease-in;
}

.nav-pointer[data-location='1'] {
  transform: translateY(4.35rem) rotate(-45deg);
}
.nav-pointer[data-location='2'] {
  transform: translateY(8.6rem) rotate(45deg);
}
.nav-pointer[data-location='3'] {
  transform: translateY(13rem) rotate(135deg);
}
.nav-pointer[data-location='4'] {
  transform: translateY(17.4rem) rotate(225deg);
}

.nav-location {
  position: relative;
  display: block;
  height: 0.25rem;
  width: 0.25rem;
  margin: 4rem 0;
  background-color: transparent;
  border: 1px solid #5c9ad2;
  border-radius: 100px;
  cursor: pointer;
}

.nav-location.selected {
  border: 1px solid #fff;
  background-color: #fff;
}

.nav-location:after {
  content: '';
  position: absolute;
  top: -0.35rem;
  left: -0.35rem;
  height: 1rem;
  width: 1rem;
  padding-left: 0;
}

.nav-location:nth-child(n + 3):before {
  content: '';
  position: absolute;
  display: block;
  top: -3.5rem;
  left: 0.1rem;
  height: 3rem;
  width: 0.05rem;
  background-color: #11365c;
  cursor: default;
}

@media only screen and (max-width: 60rem) {
  .nav-scroller {
    display: none;
  }
}

@media only screen and (max-width: 110rem) {
  .nav-scroller {
    right: 10%;
  }
}

@media only screen and (max-width: 100rem) {
  .nav-scroller {
    right: 0.75rem;
  }
}

html {
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -1px;
}

@media only screen and (max-width: 60rem) {
  html {
    font-size: 150%;
  }
}

@media only screen and (max-width: 30rem) {
  html {
    font-size: 125%;
  }
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #020910;
  color: #d3c7c3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1 {
  font-family: sans-serif;
  font-weight: 700;
  font-size: 3rem;
  letter-spacing: -2.75px;
  margin: 0;
  color: #fff;
}

section {
  height: 100vh;
  max-width: 40rem;
  margin: 0 auto;
}

.intro-container {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
}

.intro-container span {
  display: block;
  margin-left: 1.25rem;
  color: #5c9ad2;
  line-height: 1.75rem;
}

.intro-container .subtitle {
  font-size: 1.15rem;
  margin-top: 1.5rem;
}

.intro-container img {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media only screen and (max-width: 30rem) {
  .intro-container {
    padding: 0 1rem;
  }
  .intro-container img {
    top: 1rem;
    right: 1rem;
  }
}

section.projects {
  padding: 0;
}

.projects-container {
  display: grid;
  grid-template-rows: 30% 70%;
  height: 100%;
}

.projects-container h1 {
  font-size: 2.5rem;
}

.projects-container h1 span {
  color: #5c9ad2;
}

.projects-container .header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 3rem);
  padding: 0 1.5rem;
}

.projects-container .header img {
  display: inline-block;
  height: 1rem;
  margin-right: 0.25rem;
}

.projects-container .subtitle {
  position: relative;
  display: inline-block;
  font-size: 1.25rem;
  width: max-content;
  max-width: 60%;
  margin: 0.25rem 0 2.25rem;
}

.projects-container .subtitle span.maintitle {
  font-weight: 600;
  color: #5c9ad2;
}

.projects-container .header .skills-list {
  position: absolute;
  right: 0;
  bottom: -2.5rem;
}

.projects-container .header .skills-list svg {
  height: 1.25rem;
  width: 1.25rem;
  margin-left: 0.5rem;
}

.projects-container .header .description {
  position: absolute;
  bottom: 0;
  right: 1.5rem;
  width: 27%;
  padding: 1rem 1rem 0 1rem;
}

.projects-container .header .description p {
  font-size: 0.65rem;
  color: #5c9ad2;
  font-weight: 500;
  margin: 0;
}

.projects-container .header .description h2 {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  line-height: 1.2;
}

.projects-container .header .description .close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.75rem;
  cursor: pointer;
}

.projects-list {
  overflow: hidden;
  padding: 1rem 0;
}

.project-display {
  position: relative;
  background-color: #020910;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.project-display img {
  width: 100%;
}

.project-display .project-details {
  position: absolute;
  background-image: linear-gradient(90deg, #0b1927cc, #0b1927cc);
  width: 27%;
  right: 0;
  top: -0.5rem;
  padding: 1rem 1rem 0.75rem 1rem;
  border-radius: 15px;
  margin-right: 1.5rem;
}

.project-display .project-details li {
  position: relative;
  font-size: 0.75rem;
  margin: 0.25rem 0 0 1rem;
  font-weight: 400;
}

.project-display .project-details li svg {
  position: absolute;
  left: -1rem;
  top: 0.15rem;
  height: 0.75rem;
  width: 0.75rem;
  fill: #fff;
}

.project-display .project-details .view-website {
  width: 65%;
  color: #5c9ad2;
  text-align: center;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  font-family: Open Sans, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.3s ease-in;
  position: absolute;
  bottom: -2.3rem;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
  box-shadow: 0 0 5px #000000bf;
  cursor: pointer;
}

.project-display .project-details .view-website .mobile-friendly {
  display: none;
  color: red;
  font-size: 0.9rem;
}

.projects-list ul.project-list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 50%;
  gap: 0.5rem;
  height: 100%;
  padding: 1.5rem 1.5rem;
  background-color: #020910;
  transform: translateY(calc(-100% + 1.5rem));
  transition: all 0.3s ease-out;
}

.projects-list ul.hide,
.projects-list ul.hidden {
  transform: translateY(5%);
}

.projects-list ul .item {
  position: relative;
  background-color: #fff;
  height: 100%;
  min-height: 6rem;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.projects-list ul .item.hover,
.projects-list ul .item.selected {
  transform: translateY(-0.75rem);
}

.projects-list ul .item.hover:after,
.projects-list ul .item.selected:after {
  position: absolute;
  content: '';
  height: calc(100% - 0.28rem);
  width: 100%;
  border-bottom: 0.3rem solid #5c9ad2;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.projects-list ul .item img {
  height: 175%;
  transform: 0;
}

@media only screen and (max-width: 80rem) {
  .project-display .project-details .view-website .mobile-friendly {
    display: block;
    font-size: 0.5rem;
  }
}

@media only screen and (max-width: 30rem) {
  section.projects {
    height: 120vh;
  }

  .projects-container {
    grid-template-rows: auto;
  }

  .projects-container .header {
    width: calc(100vw - 2rem);
    padding: 0 1rem;
    height: auto;
    margin-top: 2rem;
  }

  .projects-container .subtitle {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 1rem 0 1.25rem;
    font-size: 1.5rem;
    line-height: 1.2;
  }

  .projects-container .subtitle img {
    height: 1.25rem;
    position: absolute;
    top: 0.4rem;
    left: 0;
  }

  .projects-container .subtitle span.maintitle {
    margin-left: 1.5rem;
  }
  .projects-container .subtitle span.shortdesc {
    display: none;
  }

  .projects-container .subtitle .skills-list {
    top: -3rem;
    right: 0;
  }

  .projects-container .header .description {
    position: relative;
    width: 100%;
    padding: 0 1.5rem;
  }

  .projects-container .header .description p {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .projects-container .header .description .close {
    right: 1.5rem;
    top: 0;
    font-weight: 700;
  }

  .projects-list ul.project-list {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(10, 175px);
    padding: 1.5rem 1rem;
  }

  .projects-list ul.project-list .item.selected {
    transform: translateY(0);
  }

  .projects-list ul.project-list .item.selected:after {
    border-bottom: none;
  }

  .project-display {
    width: 100vw;
  }

  .project-display img {
    height: 100%;
    width: auto;
  }

  .project-display .project-details {
    width: calc(100% - 2rem);
    margin: 0;
  }

  .project-display .project-details li {
    font-size: 1rem;
  }

  .project-display .project-details .view-website {
    position: initial;
    display: block;
    width: calc(100% - 2rem);
    margin-top: 1rem;
    font-size: 1.25rem;
    transform: none;
    background-color: transparent;
    color: #fff;
  }

  .project-display .project-details .view-website .mobile-friendly {
    font-size: 0.9rem;
  }
}

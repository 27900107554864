.skills-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.5rem;
  height: 100%;
  padding: 0 1.5rem;
}

.skills-container .textarea {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.skills-container .textarea h1 {
  font-size: 2.25rem;
  line-height: 0.9;
}

.skills-container .textarea h1 span {
  color: #5c9ad2;
}

.skills-container .textarea p.subtext {
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.skills-container .textarea p {
  margin-bottom: 0;
  font-size: 0.75rem;
}

.skills {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.skills .skill {
  position: absolute;
  font-size: 1.25rem;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  z-index: 2;
}

.skills .skill:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  height: 120%;
  width: 130%;
  background-color: #020910;
  border-radius: 50px;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.skills .skill.small {
  font-size: 0.75rem;
}

.skills .skill.pop {
  opacity: 1;
  animation: pop 0.75s linear 1;
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  30% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.5);
  }
  80% {
    transform: scale(0.75);
  }
}

.bg--circle--outer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  width: 100%;
  padding-top: 100%;
  border: 1px solid #5c9ad2;
  border-radius: 100%;
  opacity: 0;

  transition: all 1s ease-out;
}

.bg--circle--outer.show {
  opacity: 1;
}

.bg--circle--outer .skill:first-child {
  top: -4%;
  left: 38%;
}
.bg--circle--outer .skill:nth-child(2) {
  top: 14%;
  left: 8%;
}
.bg--circle--outer .skill:nth-child(3) {
  top: 28%;
  right: -2%;
}
.bg--circle--outer .skill:nth-child(4) {
  top: 65%;
  left: -4%;
}
.bg--circle--outer .skill:nth-child(5) {
  top: 85%;
  left: 15%;
}
.bg--circle--outer .skill:nth-child(6) {
  top: 88%;
  right: 10%;
}

.bg--circle--inner {
  position: absolute;
  height: 60%;
  width: 60%;
  top: 20%;
  left: 20%;
  border: 1px solid #5c9ad2;
  border-radius: 100%;
}

.bg--circle--inner .skill:first-child {
  top: 25%;
  left: -8%;
}
.bg--circle--inner .skill:nth-child(2) {
  top: 5%;
  left: unset;
  right: 13%;
}
.bg--circle--inner .skill:nth-child(3) {
  top: 60%;
  left: unset;
  right: -7%;
}
.bg--circle--inner .skill:nth-child(4) {
  top: 93%;
  left: 30%;
}

@media only screen and (max-width: 30rem) {
  section.skills {
    height: auto;
  }
  .skills-container {
    grid-template-columns: 1fr;
    margin-top: 2rem;
    padding: 0 1rem;
  }

  .skills-container .textarea p.subtext {
    font-size: 1.15rem;
  }

  .skills-container .textarea p {
    font-size: 0.9rem;
  }
}

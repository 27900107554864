section.contact {
  height: 50vh;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
}

.contact-container h1 {
  font-size: 2.5rem;
  transform: translateX(-4rem);
}

.contact-container span {
  color: #5c9ad2;
}

.contact-container .subtitle {
  font-size: 1rem;
  margin-top: 1.5rem;
  transform: translate(2rem, 0) rotate(-5deg);
  cursor: pointer;
  width: max-content;
  margin: 0;
}

.contact-container .subtitle span {
  color: #5c9ad2;
  font-weight: 600;
}

@media only screen and (max-width: 30rem) {
  .contact-container {
    align-items: flex-start;
    padding: 0 1rem;
  }
  .contact-container h1 {
    font-size: 2.5rem;
    transform: translateX(0);
  }
  .contact-container .subtitle {
    font-size: 1.25rem;
    transform: translate(-0.5rem, 0.25rem) rotate(-5deg);
  }
}

.websiteview {
  height: 100vh;
}

.websiteview h1 {
  font-size: 2.5rem;
  line-height: 0.9;
  margin: 0 1.5rem;
  padding: 1rem 0 0;
}

.websiteview span {
  color: #5c9ad2;
}

.websiteview p {
  font-size: 1.25rem;
  margin: 0.25rem 1.5rem 1rem;
}

.websiteview .imgcontainer {
  width: 100%;
  height: 75%;
}

.websiteview a {
  width: 100%;
  height: 100%;
}

.websiteview .websiteimg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 60rem) {
  .websiteview .imgcontainer {
    height: 50%;
  }
}

@media only screen and (max-width: 30rem) {
  .websiteview h1 {
    margin: 0 1rem;
  }

  .websiteview p {
    margin: 0.25rem 1rem 1rem;
  }

  .websiteview .imgcontainer {
    height: 30%;
  }
}
